.footer-text {
  text-align: center;
  color: #868e96;
  font-size: small;
}

.footer-div {
  margin-top: 2rem;

}
